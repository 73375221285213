import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import { useSettings, useStyles } from '@wix/tpa-settings/react';
import settingsParams from 'Groups/settingsParams';
import stylesParams from 'Groups/stylesParams';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';

import { Box } from 'wui/Box';

import { Show } from 'wui/Show';
import { Typography } from 'wui/Typography';

import { CentralFeed } from '../../CentralFeed/loadable';

import classes from './FeedWithTitle.scss';

export function FeedWithTitle(props: TPAComponentProps) {
  const styles = useStyles();
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const { centralFeed$ } = useController();

  const showFeed = settings.get(settingsParams.showFeed);
  const { htmlTag } = styles.get(stylesParams.feedTitleFont);

  useDidUpdate(() => {
    if (showFeed) {
      centralFeed$.fetch();
    }
  }, [showFeed]);

  if (isMobile) {
    return (
      <Show if={showFeed}>
        <Box
          className={cls(classes.root, classes.mobile)}
          data-hook={props['data-hook']}
        >
          <Typography
            as={htmlTag as React.ElementType}
            variant="h2-20"
            id="feed-title"
            className={classes.title}
            data-hook="feed-title"
          >
            {settings.get(settingsParams.feedTitle)}
          </Typography>
        </Box>
        <CentralFeed className={classes.feed} data-hook="central-feed" />
      </Show>
    );
  }

  return (
    <Show if={showFeed}>
      <Box className={classes.wrapper} data-hook={props['data-hook']}>
        <Box direction="vertical" className={classes.root}>
          <Typography
            as={htmlTag as React.ElementType}
            variant="h2-20"
            id="feed-title"
            data-hook="feed-title"
            className={classes.title}
          >
            {settings.get(settingsParams.feedTitle)}
          </Typography>
          <CentralFeed className={classes.feed} data-hook="central-feed" />
        </Box>
      </Box>
    </Show>
  );
}

FeedWithTitle.displayName = 'FeedWithTitle';
