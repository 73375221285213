import React from 'react';
import { useStyles } from '@wix/tpa-settings/react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import stylesParams from 'Groups/stylesParams';

import { Box } from 'wui/Box';
import { Card } from 'wui/Card';
import { type TPAComponentProps } from 'wui/types';

import { Groups } from './Groups';
import { Header } from '../Header';
import { PendingGroups } from '../../PendingGroups';
import { FeedWithTitle } from '../FeedWithTitle';

const { groupCardListTopBottomSpacing, groupCardListSideSpacing } =
  stylesParams;
export function ListLayout(props: TPAComponentProps) {
  const styles = useStyles();
  const { isMobile } = useEnvironment();

  if (isMobile) {
    return (
      <Box gap="SP0" direction="vertical" {...props}>
        <Header origin="group_list" data-hook="list-layout-header" />
        <PendingGroups data-hook="pending-groups" />
        <Groups />
        <FeedWithTitle data-hook="feed-with-title" />
      </Box>
    );
  }

  return (
    <Box gap="SP6" direction="vertical" {...props}>
      <Header origin="group_list" data-hook="list-layout-header" />

      <PendingGroups data-hook="pending-groups" />

      <Card
        paddingTop={styles.get(groupCardListTopBottomSpacing)}
        paddingBottom={styles.get(groupCardListTopBottomSpacing)}
        paddingLeft={styles.get(groupCardListSideSpacing)}
        paddingRight={styles.get(groupCardListSideSpacing)}
      >
        <Groups />
      </Card>
      <FeedWithTitle data-hook="feed-with-title" />
    </Box>
  );
}

ListLayout.displayName = 'ListLayout';
