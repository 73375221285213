import React from 'react';
import cls from 'classnames';
import { useRouter } from '@wix/tpa-router/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import type { IGroup } from 'store/groups';
import { groupsSelectGroupFromList } from '@wix/bi-logger-groups/v2';

import { Box } from 'wui/Box';
import { ListItem } from 'wui/ListItem';
import { ListItemText } from 'wui/ListItemText';

import { ListItemIcon } from 'wui/ListItemIcon';
import { ListItemButton } from 'wui/ListItemButton';
import type { TPAComponentProps } from 'wui/types';

import { GroupInfo } from 'common/components/GroupInfo';
import { GroupImage } from 'common/components/GroupImage';

import { EImageRatio } from 'settings/constants';

import classes from './GroupListItem.scss';

interface IGroupListItemProps extends TPAComponentProps {
  wired?: boolean;
  group: IGroup;
}

export function JoinedGroupListItem(props: IGroupListItemProps) {
  const { group, wired, className, ...rest } = props;
  const router = useRouter();
  const { isMobile } = useEnvironment();
  return (
    <ListItem
      {...rest}
      disablePadding
      className={cls(className, { [classes.wired]: wired })}
    >
      <ListItemButton
        disablePadding={isMobile}
        onClick={handleGroupClick}
        data-hook="group-list-item-cta"
        className={cls({ [classes['item-button']]: !isMobile })}
      >
        <ListItemIcon className={classes.image}>
          <Box width={40} height={40}>
            <GroupImage
              width={40}
              height={40}
              resize="cover"
              className={classes.image}
              image={group.coverImage?.image}
              aspectRatio={EImageRatio.Square}
            />
          </Box>
        </ListItemIcon>

        <ListItemText
          title={group.name}
          titleProps={{
            noWrap: true,
            variant: isMobile ? 'p2-14' : 'p2-16',
            className: classes.name,
          }}
          subtitle={
            <GroupInfo
              extended={false}
              data-hook="group-info"
              misc
              wired={wired}
              groupId={group.id as string}
            />
          }
        />
      </ListItemButton>
    </ListItem>
  );

  function handleGroupClick() {
    router.go('group', { slug: group.slug });
    groupsSelectGroupFromList({
      groupId: group.id as string,
      origin: 'new_layout_groups_sidebar_my_groups',
    });
  }
}

JoinedGroupListItem.displayName = 'JoinedGroupListItem';
