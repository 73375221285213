import React from 'react';
import cls from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Search as SearchIcon } from '@wix/wix-ui-icons-common/on-stage';
import { IconButton } from '../IconButton';
import { TextField } from '../TextField';

import classes from './Search.scss';

export interface IProps extends React.ComponentProps<typeof TextField> {}

export function Search(props: IProps) {
  const {
    className,
    onBlur,
    onFocus,
    onClear,
    inputClassName,
    autoFocus,
    ...rest
  } = props;

  const [isExpanded, setIsExpanded] = React.useState(autoFocus);
  const { t } = useTranslation();

  return (
    <TextField
      wired
      className={cls(classes.root, className, {
        [classes.expanded]: isExpanded,
      })}
      inputClassName={cls(classes.input, inputClassName)}
      autoFocus={isExpanded && autoFocus}
      onBlur={handleBlur}
      onFocus={handleFocus}
      theme="line"
      clearButtonAriaLabel="clear"
      onClear={handleClear}
      prefix={
        <IconButton
          aria-label={t('groups-web.a11y.search')}
          icon={<SearchIcon />}
          className={classes.search}
          onClick={handleSearchClick}
        />
      }
      {...rest}
    />
  );

  function handleClear() {
    onClear?.();
    setIsExpanded(false);
  }

  function handleSearchClick() {
    setIsExpanded(true);
  }

  function handleFocus(event: React.FocusEvent<HTMLInputElement>) {
    setIsExpanded(true);
    onFocus?.(event);
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    if (!props.value) {
      setIsExpanded(false);
    }

    onBlur?.(event);
  }
}

Search.displayName = 'wui/Search';
