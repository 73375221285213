import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import type { IGroupRequest } from '@wix/social-groups-serverless/dist/group-requests/types';

import { DialogContent } from 'wui/DialogContent';
import { DialogTitle } from 'wui/DialogTitle';
import { AlertDialog } from 'wui/AlertDialog';
import { DialogActions } from 'wui/DialogActions';
import { Button } from 'wui/Button';
import { DialogContentText } from 'wui/DialogContentText';

import { selectGroupRequestsStatuses } from 'store/selectors';
import { useController } from 'common/context/controller';

import { DELETE_GROUP_REQUEST_DIALOG } from './dataHooks';
import { Typography } from 'wui/Typography';

interface IProps extends React.ComponentProps<typeof AlertDialog> {
  request: IGroupRequest;
}

export function DeleteGroupRequestDialog(props: IProps) {
  const { request, ...rest } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const { groupRequests$ } = useController();

  const statuses = useSelector(selectGroupRequestsStatuses);

  const status = statuses.cancel[request?.id as string] || {};

  useEffect(() => {
    if (!status.loading && !status.error) {
      props.onClose();
    }
  }, [status.loading]);

  return (
    <AlertDialog
      aria-labelledby="dgr-dialog-title"
      aria-describedby="dgr-dialog-content"
      paperProps={{ 'data-hook': DELETE_GROUP_REQUEST_DIALOG }}
      {...rest}
    >
      <DialogTitle
        alert
        id="dgr-dialog-title"
        title={t('groups-web.modal.delete-group-request.title')}
      />
      <DialogContent alert>
        <DialogContentText alert>
          <Typography
            id="dgr-dialog-content"
            variant={isMobile ? 'p2-14' : 'p2-16'}
          >
            <Trans
              i18nKey="groups-web.modal.delete-group-request.subtitle"
              components={{ b: <b /> }}
              values={{ groupName: request?.group?.name }}
            />
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions alert>
        <Button
          outlined
          variant="basic"
          onClick={props.onClose}
          fullWidth={isMobile}
        >
          {t('groups-web.modal.decline-group.action.cancel')}
        </Button>
        <Button
          variant="basic"
          onClick={handleDelete}
          loading={status.loading}
          fullWidth={isMobile}
        >
          {t('groups-web.modal.decline-group.action.delete')}
        </Button>
      </DialogActions>
    </AlertDialog>
  );

  function handleDelete() {
    groupRequests$.cancel(request.id);
  }
}

DeleteGroupRequestDialog.displayName = 'DeleteGroupRequestDialog';
