import React from 'react';
import {
  ErrorMonitorBoundary,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { SwitchTransition, TransitionGroup } from 'react-transition-group';

import type { PagingMetadata } from '@wix/ambassador-social-groups-v2-group/types';
import type { IGroup, IGroupListOperationStatus } from 'store/groups';

import { ChevronDown as ChevronDownIcon } from '@wix/wix-ui-icons-common/on-stage';
import { TextButton } from 'wui/TextButton';
import { Box } from 'wui/Box';
import { List } from 'wui/List';
import { Fade } from 'wui/Fade';
import { Show } from 'wui/Show';
import { ErrorState } from 'wui/ErrorState';
import type { TPAComponentProps } from 'wui/types';

import { GroupListItem } from './GroupListItem';
import { getPlaceholders } from './GroupList.skeleton';

interface IGroupListProps extends TPAComponentProps {
  wired?: boolean;
  groups: IGroup[];
  meta: PagingMetadata;
  status: IGroupListOperationStatus;

  emptyState: React.ReactElement;

  onRetry(): void;
  onLoadMore?(): void;
}

export function GroupList(props: IGroupListProps) {
  const { groups, wired, meta, status } = props;

  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  const hasMore = groups.length < (meta.total as number);

  return (
    <ErrorMonitorBoundary
      fallback={
        <ErrorState
          variant="section"
          data-hook={props['data-hook']}
          data-error="unexpected"
          title={t('groups-web.error-state.title')}
          subtitle={t('groups-web.toast.error.groups.query')}
          actionLabel={t('groups-web.error-state.retry.label')}
        />
      }
    >
      <SwitchTransition>
        {(() => {
          if (status.fetch?.error) {
            return (
              <Fade key="error">
                <ErrorState
                  variant="section"
                  data-hook={props['data-hook']}
                  data-error="fetch"
                  onRetry={props.onRetry}
                  title={t('groups-web.error-state.title')}
                  subtitle={t('groups-web.toast.error.groups.query')}
                  actionLabel={t('groups-web.error-state.retry.label')}
                />
              </Fade>
            );
          }

          if (!groups.length) {
            return (
              <Fade key="empty">
                <>{props.emptyState}</>
              </Fade>
            );
          }

          return (
            <Fade key="list">
              <>
                <List
                  disablePadding
                  className={props.className}
                  data-hook={props['data-hook']}
                  gap={isMobile ? 'SP4' : undefined}
                >
                  <TransitionGroup component={null}>
                    {groups.map((group) => (
                      <Fade key={group.id}>
                        <GroupListItem
                          wired={wired}
                          group={group}
                          data-hook="group-list-item"
                        />
                      </Fade>
                    ))}
                  </TransitionGroup>
                  <Show if={status.fetchMore?.loading}>
                    <Box
                      direction="vertical"
                      padding={isMobile ? undefined : '0 SP5'}
                    >
                      {getPlaceholders(5, wired)}
                    </Box>
                  </Show>
                </List>
                <Show if={hasMore && !!props.onLoadMore}>
                  <Box
                    paddingLeft={isMobile ? 'SP0' : 'SP5'}
                    paddingRight={isMobile ? 'SP0' : 'SP5'}
                    paddingTop={isMobile ? undefined : 'SP2'}
                  >
                    <TextButton
                      wired={wired}
                      onClick={props.onLoadMore}
                      prefixIcon={<ChevronDownIcon />}
                    >
                      {t('groups-web.showMore')}
                    </TextButton>
                  </Box>
                </Show>
              </>
            </Fade>
          );
        })()}
      </SwitchTransition>
    </ErrorMonitorBoundary>
  );
}

GroupList.displayName = 'GroupList';
