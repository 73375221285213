import React from 'react';
import cls from 'classnames';

import {
  FloatingDropdown,
  FloatingDropdownOptionProps,
  FloatingDropdownProps,
} from 'wix-ui-tpa/cssVars';

import classes from './Dropdown.scss';
import { classes as stClasses } from './Dropdown.st.css';

export interface IDropdownProps extends FloatingDropdownProps {}

export function Dropdown(props: IDropdownProps) {
  const { className, ...rest } = props;

  return (
    <FloatingDropdown
      className={cls(classes.root, stClasses.root, className)}
      {...rest}
    />
  );
}

Dropdown.displayName = 'wui/Dropdown';

export type Option = FloatingDropdownOptionProps;
