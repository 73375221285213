import React from 'react';
import cls from 'classnames';

import { EImageRatio } from 'settings/constants';

import type { IGroup } from 'store/groups/types';

import { Box } from 'wui/Box';
import { Skeleton } from 'wui/Skeleton';
import { ListItemIcon, type IListItemIconProps } from 'wui/ListItemIcon';
import { AspectRatio } from 'wui/AspectRatio';

import { GroupImage } from 'common/components/GroupImage';

import classes from './GroupListItem.scss';

interface IGroupListItemImageProps extends Partial<IListItemIconProps> {
  group: IGroup;
}

export function GroupListItemImage(props: IGroupListItemImageProps) {
  const { group, className, ...rest } = props;

  return (
    <ListItemIcon className={cls(classes.image, className)} {...rest}>
      <Box width={40}>
        <GroupImage
          width={40}
          resize="cover"
          aspectRatio={EImageRatio.Square}
          image={group.coverImage?.image}
        />
      </Box>
    </ListItemIcon>
  );
}

GroupListItemImage.displayName = 'GroupListItemImage';

export function GroupListItemImageSkeleton() {
  return (
    <ListItemIcon className={classes.image}>
      <AspectRatio width={40} aspectRatio={EImageRatio.Square}>
        <Skeleton variant="rect" width="100%" height="100%" />
      </AspectRatio>
    </ListItemIcon>
  );
}

GroupListItemImageSkeleton.displayName = 'GroupListItemImageSkeleton';
