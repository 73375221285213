import React from 'react';
import cls from 'classnames';
import {
  useBi,
  useEnvironment,
  useTranslation,
  useExperiments,
} from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams, useRouter } from '@wix/tpa-router/react';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useSelector } from 'react-redux';
import { Divider } from 'wui/Divider';

import { debounce } from 'lodash';

import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';
import { Search as SearchIcon } from '@wix/wix-ui-icons-common/on-stage';
import { EGroupPartition } from '@wix/social-groups-serverless/dist/groups/types';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Paper } from 'wui/Paper';
import { Typography } from 'wui/Typography';
import { Dropdown, Option } from 'wui/Dropdown';
import { TextField } from 'wui/TextField';
import { TextButton } from 'wui/TextButton';
import { Search as SearchInput } from 'wui/Search';

import { useController } from 'common/context/controller';
import { useBiParams } from 'common/hooks/useBiParams';

import settingsParams from 'Groups/settingsParams';
import stylesParams from 'Groups/stylesParams';
import { EGroupListSort } from 'settings/constants';

import {
  selectCanCreateGroup,
  selectCanCreateGroupRequests,
} from 'store/selectors';

import { Search as SearchDrawer } from '../../Search';

import {
  HEADER_CREATE_GROUP_BUTTON,
  HEADER_SEARCH_BUTTON,
  HEADER_SEARCH_DRAWER,
} from './dataHooks';

import classes from './Header.scss';
import { IconButton } from 'wui/IconButton';

type HeaderProps = {
  origin: string;
  'data-hook'?: string;
};

export function Header({ origin, 'data-hook': dataHook }: HeaderProps) {
  const settings = useSettings();
  const { experiments } = useExperiments();
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const bi = useBi();
  const biParams = useBiParams();

  const router = useRouter();
  const { params } = useCurrentStateAndParams();
  const { application$ } = useController();

  const showFeed = settings.get(settingsParams.showFeed);
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const delayedQuery = React.useCallback(debounce(searchGroups, 500), []);

  React.useEffect(() => {
    setQuery(params.title || '');
  }, [params.title || '']);

  const canCreateGroup = useSelector(selectCanCreateGroup);
  const canCreateGroupRequests = useSelector(selectCanCreateGroupRequests);

  const showCreateGroupButton = canCreateGroup || canCreateGroupRequests;

  const { htmlTag: htmlTitleTag } = styles.get(stylesParams.listTitleFont);
  const { htmlTag: htmlSubtitleTag } = styles.get(
    stylesParams.listSubtitleFont,
  );

  if (isMobile) {
    return (
      <Box direction="vertical" gap="SP0" data-hook={dataHook}>
        <Box padding="SP5" direction="vertical" gap="SP5">
          <Box direction="vertical">
            <Typography
              as={htmlTitleTag as React.ElementType}
              variant="h2-20"
              data-hook="group-list-title"
              className={cls(classes.title, classes.mobile)}
            >
              {settings.get(settingsParams.groupListTitle)}
            </Typography>

            <Typography
              as={htmlSubtitleTag as React.ElementType}
              variant="h2-20"
              data-hook="group-list-title"
              className={classes.subtitle}
            >
              {settings.get(settingsParams.groupListSubtitle)}
            </Typography>
          </Box>

          <Box gap="SP6" verticalAlign="middle" align="space-between">
            <Show if={showCreateGroupButton}>
              <TextButton
                onClick={openCreateGroupDialog}
                className={classes.createButton}
                data-hook={HEADER_CREATE_GROUP_BUTTON}
              >
                {t('groups-web.btn.create-new')}
              </TextButton>
            </Show>
            <Show if={showFeed}>
              <IconButton
                aria-label={t('groups-web.a11y.search')}
                icon={<SearchIcon />}
                onClick={handleSearchClick}
                data-hook={HEADER_SEARCH_BUTTON}
              />
              <SearchDrawer
                data-hook={HEADER_SEARCH_DRAWER}
                isOpen={isSearchOpen}
                onClose={() => setIsSearchOpen(false)}
                origin={origin}
              />
            </Show>
          </Box>
        </Box>

        <Show if={!showFeed}>
          <Paper sideBorders={false} className={classes.searchSort}>
            <Box direction="vertical" padding="SP5">
              <TextField
                className={classes.mobileSearch}
                prefix={<SearchIcon />}
                value={query}
                placeholder={t('groups-web.search.placeholder')}
                onChange={(evt) => handleQueryChange(evt.target?.value)}
              />
            </Box>
          </Paper>
        </Show>
      </Box>
    );
  }

  return (
    <Box
      align="space-between"
      direction="vertical"
      gap="SP6"
      data-hook={dataHook}
    >
      <Box gap="SP2" verticalAlign="middle" direction="vertical">
        <Typography
          as={htmlTitleTag as React.ElementType}
          variant="h2-20"
          data-hook="group-list-title"
          className={classes.title}
        >
          {settings.get(settingsParams.groupListTitle)}
        </Typography>

        <Typography
          as={htmlSubtitleTag as React.ElementType}
          variant="h2-20"
          data-hook="group-list-title"
          className={classes.subtitle}
        >
          {settings.get(settingsParams.groupListSubtitle)}
        </Typography>
      </Box>
      <Box verticalAlign="space-between">
        <Box>
          <Box verticalAlign="middle" className={classes.searchSort}>
            <Dropdown
              className={classes.sortDropdown}
              onChange={handleSortChange}
              label={t('groups-web.group-list.sort') + ':'}
              placeholder={t('groups-web.members.filter.all')}
              value={params.sort}
              options={[
                {
                  isSelectable: true,
                  id: EGroupListSort.Activity,
                  value: t('groups-web.group-list.sort.recent-activity'),
                },
                {
                  isSelectable: true,
                  id: EGroupListSort.Name,
                  value: t('groups-web.group-list.sort.alphabetical'),
                },
                {
                  isSelectable: true,
                  id: EGroupListSort.Members,
                  value: t('groups-web.group-list.sort.member-count'),
                },
              ]}
            />

            <Box gap="SP6" verticalAlign="middle">
              <Box height="24px">
                <Divider orientation="vertical" />
              </Box>
              <Show if={showFeed}>
                <IconButton
                  aria-label={t('groups-web.a11y.search')}
                  icon={<SearchIcon />}
                  data-hook={HEADER_SEARCH_BUTTON}
                  onClick={handleSearchClick}
                />
                <SearchDrawer
                  isOpen={isSearchOpen}
                  data-hook={HEADER_SEARCH_DRAWER}
                  onClose={() => setIsSearchOpen(false)}
                  origin={origin}
                />
              </Show>
              <Show if={!showFeed}>
                <SearchInput
                  withClearButton
                  className={classes.search}
                  autoFocus={!!params.title}
                  value={query}
                  onChange={(evt) => handleQueryChange(evt?.target?.value)}
                  placeholder={t('groups-web.search.placeholder')}
                  onClear={() => handleQueryChange('')}
                />
              </Show>
            </Box>
          </Box>
        </Box>
        <Show if={showCreateGroupButton}>
          <TextButton
            data-hook={HEADER_CREATE_GROUP_BUTTON}
            onClick={openCreateGroupDialog}
          >
            {t('groups-web.btn.create-new')}
          </TextButton>
        </Show>
      </Box>
    </Box>
  );

  // function handleOrderChange() {
  //   router.go('.', { order: params.order === 'ASC' ? 'DESC' : 'ASC' });
  // }

  function handleSearchClick() {
    setIsSearchOpen(true);
  }

  function openCreateGroupDialog() {
    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreen,
        button_name: 'create_group',
      }),
    );
    application$.showDialog({ dialog: 'createGroup' });
  }

  function handleSortChange(option: Option) {
    if (option.id === params.sort) {
      return;
    }

    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreen,
        button_name: 'sort_by',
      }),
    );

    router.go(
      '.',
      { sort: option.id, disableScrollToTop: true },
      { location: 'replace' },
    );
  }

  function handleQueryChange(query: string) {
    setQuery(query);
    delayedQuery(query);
  }

  function searchGroups(value: string) {
    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreen,
        button_name: 'search',
      }),
    );
    router.go(
      '.',
      {
        title: value,
        disableScrollToTop: true,
        partition: value ? EGroupPartition.ALL : params.partition,
      },
      { location: 'replace' },
    );
  }
}

Header.displayName = 'Header';
