import React, { useState } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { UISref, useRouter } from '@wix/tpa-router/react';

import { More as MoreIcon } from '@wix/wix-ui-icons-common/on-stage';
import { IGroupRequest } from '@wix/social-groups-serverless/dist/group-requests/types';

import { TextButton } from 'wui/TextButton';
import { IconButton } from 'wui/IconButton';
import { ListItem } from 'wui/ListItem';
import { ListItemText } from 'wui/ListItemText';
import { ListItemAction } from 'wui/ListItemAction';
import { Button } from 'wui/Button';
import { Typography } from 'wui/Typography';
import { Show } from 'wui/Show';
import { Menu, MenuItem } from 'wui/Menu';

import { OPEN_DELETE_GROUP_REQUEST_DIALOG } from '../dataHooks';
import { DeleteGroupRequestDialog } from '../DeleteGroupRequestDialog';

interface IProps {
  request: IGroupRequest;
  'data-hook'?: string;
}

export function GroupRequest(props: IProps) {
  const { request, 'data-hook': dataHook } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <ListItem disablePadding key={request.id} data-hook={dataHook}>
      <ListItemText
        data-hook="group-request-text"
        title={
          <Typography variant="p2-16" noWrap>
            <UISref state="group" params={{ slug: request.group.slug }}>
              <TextButton as="a" variant="secondary">
                {request.group.name}
              </TextButton>
            </UISref>
          </Typography>
        }
        subtitle={t('groups-web.group-list.pending-groups.status-for-creator')}
        titleProps={{ noWrap: true }}
      />
      <ListItemAction>
        <Show if={isMobile}>
          <Menu
            element={
              <IconButton
                icon={<MoreIcon />}
                aria-label={t('groups-web.a11y.more-actions')}
              />
            }
          >
            <MenuItem
              data-hook={OPEN_DELETE_GROUP_REQUEST_DIALOG}
              content={t('groups-web.group-list.pending-groups.button.delete')}
              onClick={handleCancel}
            />
            <MenuItem
              content={t('groups-web.btn.view-group')}
              onClick={handleView}
            />
          </Menu>
        </Show>

        <Show if={!isMobile}>
          <Button
            data-hook={OPEN_DELETE_GROUP_REQUEST_DIALOG}
            outlined
            onClick={handleCancel}
          >
            {t('groups-web.group-list.pending-groups.button.delete')}
          </Button>
          <Button onClick={handleView}>{t('groups-web.btn.view-group')}</Button>
        </Show>
      </ListItemAction>

      <DeleteGroupRequestDialog
        request={request}
        isOpen={isOpen}
        onClose={handleDialogClose}
      />
    </ListItem>
  );

  function handleDialogClose() {
    setIsOpen(false);
  }

  function handleView() {
    router.go('group', { slug: request.group.slug });
  }

  function handleCancel() {
    setIsOpen(true);
  }
}

GroupRequest.displayName = 'GroupRequest';
