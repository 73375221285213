import React from 'react';
import cls from 'classnames';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Accordion as TPAAccordion } from 'wix-ui-tpa/cssVars';

import { Paper } from '../Paper';

import classes from './Accordion.scss';

interface IAccordionProps extends React.ComponentProps<typeof TPAAccordion> {}

export function Accordion(props: IAccordionProps) {
  const { className, ...rest } = props;

  const { isMobile } = useEnvironment();

  return (
    <Paper sideBorders={!isMobile}>
      <TPAAccordion className={cls(classes.root, className)} {...rest} />
    </Paper>
  );
}

Accordion.displayName = 'wui/Accordion';
